import React from 'react'
import mist from "../../assets/mist.svg"

function Mist() {
    return (
        <div className='w-full top-0 absolute z-[3]'>
            <img src={mist} alt="" />

        </div>
    )
}

export default Mist